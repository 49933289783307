<template>
  <div>
    <form-section :title="$t('courierSet.labelSettings.labelPrintSettings')">
      <v-row>
        <v-col>
          <select-field
            v-model="getConfigurationData.labelType"
            :title="$t('courierSet.labelSettings.returnLabelType')"
            rules="required"
            :filed-items="labelTypes"
          />
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.labelSettings.additionalData')">
      <v-row>
        <v-col>
          <check-field
            v-model="getPiece.euroReturn"
            :label="$t('courierSet.labelSettings.euroReturn')"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getPiece.blpPieceId"
            :title="$t('courierSet.labelSettings.blpPieceId')"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { labelTypes } from '@/constants/Speditors/DHL/LabelTypes';
import { mapGetters } from 'vuex';

export default {
  props: {
    isNew: { type: Boolean, default: null },
  },
  data() {
    return {
      labelTypes,
    };
  },
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getConfigurationData() {
      return this.getConfigurationSet();
    },
    getPiece() {
      return this.getConfigurationSet().dhlDefaultValues.piece;
    },
  },
};
</script>

<style scoped></style>
