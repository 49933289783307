<template>
  <configuration-set
    v-if="!!getConfigurationSet()?.courier"
    :components="components"
    :speditor="speditor"
    :current-component="currentComponent"
    :loading="loading"
    @@changeComponent="changeComponent"
    @@save="save"
  >
    <template v-slot:content>
      <ServiceAuthorization
        v-show="currentComponent === 'ServiceAuthorization'"
        :is-new="isNew"
      />

      <LabelSettings
        v-show="currentComponent === 'LabelSettings'"
        :is-new="isNew"
      />

      <AdditionalServices
        v-show="currentComponent === 'AdditionalServices'"
        :is-new="isNew"
      />

      <ContentAndComments
        v-show="currentComponent === 'ContentAndComments'"
        :is-new="isNew"
      />

      <AdditionalPayments
        v-show="currentComponent === 'AdditionalPayments'"
        :is-new="isNew"
      />
    </template>
  </configuration-set>
</template>

<script>
import ServiceAuthorization from '@/views/Speditors/DHL/Forms/ServiceAuthorization.vue';
import LabelSettings from '@/views/Speditors/DHL/Forms/LabelSettings.vue';
import AdditionalServices from '@/views/Speditors/DHL/Forms/AdditionalServices.vue';
import AdditionalPayments from '@/views/Speditors/DHL/Forms/AdditionalPayments.vue';
import ConfigurationSet from '@/components/shared/ConfigurationSet.vue';
import { DHL } from '@/constants/Speditors/DHL/configuration/DHL';
import configurationMixin from '@/mixins/configurationMixin';
import ContentAndComments from '@/views/Speditors/DHL/Forms/ContentAndComments.vue';
import { v4 as uuidv4 } from 'uuid';
import { TEST_DHL_URL } from '@/constants/Speditors/testApi.js';
import { PROD_DHL_URL } from '@/constants/Speditors/prodApi.js';

export default {
  name: 'DHL',
  components: {
    ConfigurationSet,
    ServiceAuthorization,
    LabelSettings,
    AdditionalPayments,
    AdditionalServices,
    ContentAndComments,
  },
  mixins: [configurationMixin],
  data: () => ({
    isNew: false,
    speditor: 'DHL',
    currentComponent: 'ServiceAuthorization',
    components: [
      { code: 'ServiceAuthorization', name: 'courierSet.sideNavigation.authorizationData' },
      { code: 'AdditionalServices', name: 'courierSet.sideNavigation.additionalServices' },
      { code: 'LabelSettings', name: 'courierSet.sideNavigation.labelSettings' },
      { code: 'AdditionalPayments', name: 'courierSet.sideNavigation.paymentsSettings' },
      { code: 'ContentAndComments', name: 'courierSet.sideNavigation.contentAndComments' },
    ],
  }),
  computed: {
    getUrlAddress() {
      if (process.env.VUE_APP_BUILD_TARGET_ENVIRONMENT === 'production') {
        return `https://${PROD_DHL_URL}/webapi2/provider/service.html?ws=1`;
      }
      return `https://${TEST_DHL_URL}/webapi2/provider/service.html?ws=1`;
    },
  },
  methods: {
    setNewConfiguration() {
      DHL.tenantId = this.oidcUser.tenantId;
      DHL.id = uuidv4();
      DHL.urlAddress = this.getUrlAddress;
      this.setConfigSet({ response: DHL });
    },
  },
};
</script>
