import { render, staticRenderFns } from "./AdditionalPayments.vue?vue&type=template&id=94551550&scoped=true"
import script from "./AdditionalPayments.vue?vue&type=script&lang=js"
export * from "./AdditionalPayments.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94551550",
  null
  
)

export default component.exports