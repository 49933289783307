<template>
  <div>
    <form-section :title="$t('courierSet.authorizationData.title')">
      <v-row>
        <v-col>
          <text-field
            v-model="getAuthorizationData.userName"
            :title="$t('courierSet.authorizationData.login')"
            rules="required"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getAuthorizationData.password"
            :title="$t('courierSet.authorizationData.password')"
            type="password"
            :rules="isNew ? 'required' : ''"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-field
            v-model="getAuthorizationData.urlAddress"
            :title="$t('courierSet.authorizationData.urlAddress')"
            :rules="'required|url'"
          />
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.shipmentMethod.title')">
      <v-row>
        <v-col>
          <text-field
            v-model="getAuthorizationData.configurationName"
            :title="$t('courierSet.shipmentMethod.configurationName')"
            rules="required|not_custom_shipment"
          />
        </v-col>
        <v-col>
          <select-field
            v-model="getAuthorizationData.courierCode"
            :title="$t('courierSet.shipmentMethod.deliverySpeditor')"
            rules="required"
            :filed-items="SHIPMENT_METHOD_CODES"
            disabled
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-field
            v-model="getAuthorizationData.id"
            :title="$t('courierSet.shipmentMethod.configurationId')"
            rules="required"
            :disabled="true"
          />
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.serviceType.title')">
      <v-row>
        <v-col>
          <select-field
            v-model="getAuthorizationData.serviceType"
            :title="$t('courierSet.serviceType.serviceTransportType')"
            rules="required"
            :filed-items="serviceTypes"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { serviceTypes } from '@/constants/Speditors/DHL/ServiceTypes';
import { mapGetters } from 'vuex';
import { SHIPMENT_METHOD_CODES } from '@/constants/ShipmentMethodCodes.js';

export default {
  props: {
    isNew: { type: Boolean, default: false },
  },
  data() {
    return {
      SHIPMENT_METHOD_CODES,
      serviceTypes,
    };
  },
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getAuthorizationData() {
      return this.getConfigurationSet();
    },
  },
};
</script>

<style scoped></style>
